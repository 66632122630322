import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { ActionbarItemDirective } from './actionbar-item.directive';

// TODO: This can probably be removed when the old Medical Shop and Freigabe are removed
@Component({
  selector: 'mp-actionbar',
  standalone: true,
  templateUrl: './actionbar.component.html',
  styleUrl: './actionbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ActionbarItemDirective],
})
export class ActionbarComponent {
  @HostBinding('class') readonly class = 'mp-actionbar';
}

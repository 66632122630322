import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { ImageTypeUtils } from '@core/shared/data-access';

import { ValueElementDirective } from '../core/value-element';
import { SpinnerComponent } from '../spinner/spinner.component';

import { AvatarError } from './avatar-error.enums';
import { DisplaySize } from './display-size.type';

const AVATAR_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AvatarComponent),
  multi: true,
};

@Component({
  selector: 'mp-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule, SpinnerComponent],
  providers: [AVATAR_CONTROL_ACCESSOR],
})
export class AvatarComponent extends ValueElementDirective<File | string> implements OnInit, OnDestroy {
  /* Content Properties */
  @Input() image?: string | null;

  _initials?: string;

  @Input() set name(value: string | undefined | null) {
    this._initials = this.buildInititalsFor(value);
  }

  /* Formatting Properties */
  @Input() size: DisplaySize = 'large';

  /* Technical Properties */
  _isUpload = false;

  @Input() set upload(value: boolean) {
    this._isUpload = value;
  }

  fileSizeLimitInMB = 2;
  acceptedFormats = ImageTypeUtils.DEFAULT_ALLOWED_TYPES;

  /* Events */
  @Output() readonly removeImage = new EventEmitter<void>();
  @Output() readonly fail = new EventEmitter<AvatarError>();

  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  constructor(private readonly destroyRef: DestroyRef) {
    super();
    this.class = 'mp-avatar';
  }

  ngOnInit(): void {
    if (this.value && typeof this.value === 'string') {
      this.image = this.value;
    }
  }

  ngOnDestroy(): void {
    this.removeImage.complete();
    this.fail.complete();
  }

  private buildInititalsFor(name?: string | null): string {
    if (!name) {
      return '';
    }

    const partsOfName = name.split(' ').filter((part: string) => !!part);
    const nameCanBeSplittedInParts = partsOfName.length >= 2 && partsOfName[0].length > 0 && partsOfName[1].length > 0;

    return nameCanBeSplittedInParts
      ? partsOfName[0].charAt(0).toUpperCase() + partsOfName[1].charAt(0).toUpperCase()
      : name.charAt(0).toUpperCase();
  }

  onFileChange(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    if (eventTarget) {
      const file = eventTarget.files ? eventTarget.files[0] : null;
      if (!file) {
        return;
      }

      if (!ImageTypeUtils.isDefaultImageType(file.type)) {
        this.fail.emit(AvatarError.WRONG_FILE_TYPE);

        return;
      }

      const fileSizeLimitInByte = this.fileSizeLimitInMB * 1e6;
      if (file.size > fileSizeLimitInByte) {
        this.fail.emit(AvatarError.FILE_SIZE_LIMIT);

        return;
      }

      this.value = file;
      this.preview(file);
    }
  }

  private preview(fileData: File): void {
    this._isLoading$.next(true);

    this.convertToBase64String(fileData)
      .pipe(
        tap((fileString: string) => (this.image = fileString)),
        finalize(() => this._isLoading$.next(false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private convertToBase64String(file: File): Observable<string> {
    return new Observable<string>((subscriber) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onerror = () => {
        subscriber.error(reader.error);
      };
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };
    });
  }

  clearImage(): void {
    /* Currently this needs to be null, because otherwise a change detection
     * compare (as it's done in the TypedForm) with `undefined` would result in `false`.
     * The preset "empty"-value used when comparing is `null` here due to the backend
     * providing `null` for unset values. */
    this.image = this.value = null as any;
    this.removeImage.next();
  }

  handleImageLoadError(error?: unknown): void {
    this.clearImage();
  }
}

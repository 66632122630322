<div class="warengruppen-panel">
  <mat-card>
    <mat-card-title-group>
      <mat-card-title class="title">
        <button mat-button class="btn--bar" [disabled]="warengruppe.length === 0">
          <span *ngIf="!showDefaultLabel" class="title__label current truncate" (click)="expandPanel($event)">
            {{ currentWarengruppe?.bezeichnung }}
          </span>

          <span *ngIf="showDefaultLabel" class="title__label truncate" (click)="handleRootClicked($event)">
            <mat-icon class="material-icons-outlined">category</mat-icon>
            Alle Warengruppen
          </span>
        </button>
        <button mat-button class="btn--collapse" [disabled]="warengruppe.length === 0" (click)="collapsePanel()">
          <mat-icon class="material-icons-outlined">expand_less</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-title-group>

    <div *ngIf="warengruppe.length > 0" class="warengruppen-panel__dropdown">
      <div class="bc">
        <ul class="bc-list">
          <li
            *ngFor="let crumb of breadcrumb | slice : 0 : 3"
            [class.is--active]="!!currentWarengruppe && crumb.code === currentWarengruppe.code"
          >
            <span class="bc-item-label" [title]="crumb.bezeichnung" (click)="emitItemClicked(crumb)">
              {{ crumb.bezeichnung }}
            </span>
          </li>
        </ul>
      </div>

      <ul class="nav-list">
        <li *ngFor="let item of warengruppe">
          <mp-warengruppen-navigation-item
            [navigationItem]="item"
            [isExpanded]="activeDropdownCode === item.code"
            [isActive]="currentWarengruppe && currentWarengruppe?.code === item.code"
            (itemExpanded)="handleItemExpanded(item)"
            (itemClicked)="emitItemClicked($event)"
          >
          </mp-warengruppen-navigation-item>
        </li>
      </ul>
    </div>

    <button mat-button class="btn btn--expand" [disabled]="warengruppe.length === 0" (click)="expandPanel($event)">
      <mat-icon class="material-icons-outlined">expand_more</mat-icon>
    </button>
  </mat-card>
</div>

import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injector } from '@angular/core';

import { CustomOverlayConfig } from './custom-overlay-config';
import { CustomOverlayRef } from './custom-overlay-ref';
import { OVERLAY_DATA } from './custom-overlay-tokens';

export abstract class CustomOverlayService {
  protected readonly overlay: Overlay;

  constructor(protected injector: Injector) {
    this.overlay = this.injector.get(Overlay);
  }

  protected attachOverlayContainer<T>(
    componentType: ComponentType<T>,
    overlayRef: OverlayRef,
    customOverlayConfig: CustomOverlayConfig,
    customOverlayRef: CustomOverlayRef<T>,
  ): ComponentRef<T> {
    const injector: Injector = this.createInjector(customOverlayConfig, customOverlayRef);

    const containerPortal = new ComponentPortal(componentType, null, injector);
    return overlayRef.attach(containerPortal);
  }

  protected createInjector<T>(config: CustomOverlayConfig, customOverlayRef: CustomOverlayRef<T>): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: CustomOverlayRef, useValue: customOverlayRef },
        { provide: OVERLAY_DATA, useValue: config.payload },
      ],
    });
  }

  protected createOverlay(config: OverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }
}

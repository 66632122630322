<div
  class="filters-wrapper"
  [ngClass]="{ 'filters-wrapper--expanded': isExpanded }"
  (click)="!isExpanded && toggleExpandedState()"
>
  <div class="action-buttons">
    <button class="action-buttons__reset-filters-button" mat-button [disableRipple]="true" (click)="resetFilters()">
      <mat-icon class="material-icons-outlined action-buttons__reset-filters-button-icon">filter_alt_off</mat-icon>
      <span class="action-buttons__reset-filters-button-label">Filter zurücksetzen</span>
    </button>

    <button
      class="action-buttons__toggle-filters-button"
      mat-icon-button
      [disableRipple]="true"
      (click)="$event.stopPropagation(); toggleExpandedState()"
    >
      <mat-icon [svgIcon]="isExpanded ? 'filter_toggle_close' : 'filter_toggle_open'"></mat-icon>
    </button>
  </div>

  <div *ngIf="isExpanded; else collapsedView" class="expanded-filters" (click)="$event.stopPropagation()">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #collapsedView>
  <mp-collapsed-filters [filtersSummary]="filtersSummary"></mp-collapsed-filters>
</ng-template>

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CategoryNavigationItem } from '@mp/medical-shop/artikelsuche/data-access';

@Component({
  selector: 'mp-warengruppen-navigation-item',
  standalone: true,
  templateUrl: './warengruppen-navigation-item.component.html',
  styleUrls: ['./warengruppen-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule]
})
export class WarengruppenNavigationItemComponent {
  @HostBinding('class.nav-item--expanded') get expanded(): boolean {
    return this.isExpanded;
  }

  @Input() navigationItem!: CategoryNavigationItem;
  @Input() isExpanded = false;
  @Input() isActive = false;

  @Output() readonly itemExpanded = new EventEmitter<boolean>();
  @Output() readonly itemClicked = new EventEmitter<CategoryNavigationItem>();

  emitItemExpanded(): void {
    this.isExpanded = !this.isExpanded;
    this.itemExpanded.emit(this.isExpanded);
  }

  emitItemClicked(item: CategoryNavigationItem): void {
    this.itemClicked.emit(item);
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CatalogType } from '@mp/content-manager/artikelsuche/data-access';
import { IndustryCatalogType } from '@mp/content-manager/industriekataloge/data-access';

import { catalogTypeIconLookupTable } from './catalog-type-icon-lookuptable';

@Component({
  selector: 'mp-catalog-type-icon',
  standalone: true,
  template: `
    <mat-icon class="material-icons-outlined">
      {{ iconTable[type() ?? 'Unknown'] }}
    </mat-icon>
  `,
  imports: [MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogTypeIconComponent {
  @HostBinding('class') readonly class = 'mp-catalog-type-icon';

  readonly type: InputSignal<IndustryCatalogType | CatalogType | undefined> = input<
    IndustryCatalogType | CatalogType
  >();

  readonly iconTable = catalogTypeIconLookupTable;
}

<ng-container *ngrxLet="multiple$ as multiple">
  @if (searchable) {
    <mp-filter-search-field
      #searchField
      class="facet--searchfield"
      [searchFieldPlaceholder]="searchFieldText"
      (search)="emitNewSearchTerm($event)"
    />
  }

  @if (showStatusbar) {
    <div class="status-bar">
      <div class="option-counter-col">
        @if (showToggleAll && multiple) {
          <mat-checkbox
            [checked]="isAllSelected$ | async"
            [disabled]="filteredItemsCount === 0"
            [indeterminate]="isPartiallySelected$ | async"
            (change)="toggleSelectAll()"
          />
        }

        @if (selectedItemsCount === 0) {
          <div>
            @if (isCurrentlyFiltered$ | async) {
              <span>{{ filteredItemsCount }} / </span>
            }
            <span>{{ allItemsCount | autoSingularOrPlural: genericOptionName[0] : genericOptionName[1] }}</span>
          </div>
        }

        @if (multiple && selectedItemsCount > 0) {
          <div>
            <span
              >{{ selectedItemsCount }} /
              {{ allItemsCount | autoSingularOrPlural: genericOptionName[0] : genericOptionName[1] }}
              ausgewählt</span
            >
          </div>
        }
      </div>

      <div class="toggle-show-all-button-col">
        @if (showExpandAll) {
          <button
            mat-icon-button
            class="show-all-button"
            [disabled]="showAllItemsDisabled$ | async"
            (click)="toggleShowAllItems()"
          >
            <mat-icon>
              {{ (showAllItems$ | async) ? 'unfold_less' : 'unfold_more' }}
            </mat-icon>
          </button>
        }
      </div>
    </div>
  }

  <div class="item-list">
    <ng-container
      [ngTemplateOutlet]="multiple ? checkboxList.template : radiobuttonList.template"
      [ngTemplateOutletContext]="{
        $implicit: searchTerm?.length ? (filteredItems$ | async) ?? [] : (allItems$ | async) ?? []
      }"
    />
  </div>

  @if (shouldShowExpandMoreButton) {
    <span class="filter-item-more" (click)="showAllItems()">
      {{ (searchTerm?.length ? filteredItemsCount : allItemsCount) - visibleItemCount }}
      weitere
    </span>
  }

  @if (shouldShowCollapseExpandedFilter) {
    <span class="filter-item-more" (click)="collapseAllItem()"> weniger anzeigen </span>
  }

  @if (!filteredItemsCount) {
    <p class="no-items-message">Keine Ergebnisse</p>
  }
</ng-container>

<ng-template
  #checkboxList="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="filterItemsTemplateContextType"
  let-filteredItems
>
  @for (filterItem of filteredItems | slice: 0 : visibleItemCount; track $index) {
    <mat-checkbox
      class="mp-filter-item filter-item-multi-select"
      [class.selected]="filterItem.selected"
      [checked]="filterItem.selected"
      [disabled]="filterItem.disabled"
      (change)="
        onFilterItemChanges({
          key: filterItem.key,
          selected: $event.source.checked
        })
      "
    >
      @if (filterItem.icon) {
        <div class="filter-item-icon">
          @if (filterItem.icon[0] === '@') {
            <mat-icon class="material-icons-outlined" [svgIcon]="filterItem.icon.substr(1)" />
          }

          @if (filterItem.icon[0] !== '@') {
            <mat-icon class="material-icons-outlined">{{ filterItem.icon }}</mat-icon>
          }
        </div>
      }

      <div class="filter-item-label truncate" [title]="filterItem.label">
        {{ filterItem.label }}
      </div>

      @if (filterItem.stats !== undefined) {
        <div class="filter-item-stats">
          {{ filterItem.stats | number }}
        </div>
      }
    </mat-checkbox>
  }
</ng-template>

<ng-template
  #radiobuttonList="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="filterItemsTemplateContextType"
  let-filteredItems
>
  <mat-radio-group>
    @for (filterItem of filteredItems; track $index) {
      <mat-radio-button
        class="mp-filter-item filter-item-single-select"
        [class.selected]="filterItem.selected"
        [name]="filterItem.key"
        [value]="filterItem.value"
        [checked]="filterItem.selected"
        [disabled]="filterItem.disabled"
        (change)="
          onFilterItemChanges({
            key: filterItem.key,
            selected: $event.source.checked
          })
        "
      >
        @if (filterItem.lieferantTyp !== undefined) {
          <div class="filter-item-icon">
            <mat-icon class="material-icons-outlined">
              {{ filterItem.lieferantTyp }}
            </mat-icon>
          </div>
        }

        @if (filterItem.icon) {
          <div class="filter-item-icon">
            <mat-icon class="material-icons-outlined">
              {{ filterItem.icon }}
            </mat-icon>
          </div>
        }

        <div class="filter-item-label truncate">{{ filterItem.label }}</div>

        @if (filterItem.stats !== undefined) {
          <div class="filter-item-stats">
            {{ filterItem.stats | number }}
          </div>
        }
      </mat-radio-button>
    }
  </mat-radio-group>
</ng-template>

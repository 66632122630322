import { NgIf } from '@angular/common';
import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuItem as MatMenuItem, MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterLinkActive, RouterLink } from '@angular/router';

import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-overview-item',
  standalone: true,
  templateUrl: './overview-item.component.html',
  styleUrls: ['./overview-item.component.scss'],
  imports: [NgIf, RouterLink, RouterLinkActive, MatButtonModule, MatMenuModule, MatIconModule, MatRippleModule],
})
export class OverviewItemComponent extends ElementDirective implements AfterContentInit {
  @ContentChildren(MatMenuItem) contentChildren!: QueryList<MatMenuItem>;
  @Input() path?: string;

  contextMenuButtonVisible = false;

  isHighlighted?: boolean;
  @Input() set highlighted(value: boolean) {
    this.isHighlighted = value;
  }

  constructor() {
    super();
    this.class = 'mp-overview-item';
  }

  ngAfterContentInit(): void {
    if (this.contentChildren.length > 0) {
      this.contextMenuButtonVisible = true;
    }
  }
}

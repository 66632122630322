import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';

import { SelectOption } from '../../option';
import { SelectComponent } from '../../select';
import { FieldOption } from '../models';

@Component({
  selector: 'mp-query-field-selector',
  standalone: true,
  templateUrl: './query-field-selector.component.html',
  styleUrls: ['./query-field-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectComponent],
})
export class QueryFieldSelectorComponent {
  @HostBinding() readonly class = 'mp-query-field-selector';

  readonly selectedField: InputSignal<string> = input.required<string>();

  fieldOptions: InputSignal<FieldOption[]> = input.required<FieldOption[]>();

  readonly selectedFieldChange: OutputEmitterRef<string> = output<string>();

  protected readonly fieldSelectOptions: Signal<SelectOption<string>[]> = computed<SelectOption<string>[]>(() =>
    this.getSelectOptionsFromFieldOptions(),
  );

  onSelectedFieldChange(selectedField: string | undefined): void {
    if (!selectedField) {
      return;
    }

    this.selectedFieldChange.emit(selectedField);
  }

  onSelectOpenedChange(isSelectOpened: boolean): void {
    if (!isSelectOpened) {
      this.selectedFieldChange.emit(this.selectedField());
    }
  }

  private getSelectOptionsFromFieldOptions(): SelectOption<string>[] {
    return this.fieldOptions().map(({ label, key }) => ({ label, value: key }));
  }
}

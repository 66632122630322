import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Signal,
  WritableSignal,
  computed,
  effect,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { LocalStorageService } from '@core/shared/data-access';
import { UtilPipesModule } from '@core/shared/util';

import { ImageGalleryImage } from '../image-gallery-image';
import { ImageGalleryViewerControllerComponent } from '../image-gallery-viewer-controller/image-gallery-viewer-controller.component';

interface ImageGalleryOverlayData {
  title: string;
  images: ImageGalleryImage[];
  mediaPath: string;
  selectedImageIndex: number;
}

const SHOW_IMAGES_IN_ORIGINAL_SIZE_KEY = 'SHOW_IMAGES_IN_ORIGINAL_SIZE';

@Component({
  selector: 'mp-image-gallery-overlay',
  standalone: true,
  templateUrl: './image-gallery-overlay.component.html',
  styleUrl: './image-gallery-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatButtonModule, MatIconModule, ImageGalleryViewerControllerComponent, UtilPipesModule],
})
export class ImageGalleryOverlayComponent {
  protected readonly title: string = this.data.title;
  protected readonly images: ImageGalleryImage[] = this.data.images;
  protected readonly mediaPath: string = this.data.mediaPath;

  protected readonly selectedImageIndex: WritableSignal<number> = signal<number>(this.data.selectedImageIndex);

  protected readonly imagePath: Signal<string> = computed(
    () => this.mediaPath + this.images[this.selectedImageIndex()].filename,
  );

  protected readonly showImagesInOriginalSize: WritableSignal<boolean> = signal<boolean>(
    this.getStoredShowImagesInOriginalSizeValue(),
  );

  constructor(
    private readonly dialogRef: MatDialogRef<ImageGalleryOverlayComponent>,
    private readonly localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) private readonly data: ImageGalleryOverlayData,
  ) {
    effect(() =>
      this.localStorageService.write<boolean>(SHOW_IMAGES_IN_ORIGINAL_SIZE_KEY, this.showImagesInOriginalSize()),
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private getStoredShowImagesInOriginalSizeValue(): boolean {
    return this.localStorageService.read<boolean>(SHOW_IMAGES_IN_ORIGINAL_SIZE_KEY, false);
  }
}

<div class="nav-item" [class.nav-item--active]="isActive" [ngClass]="isExpanded ? 'nav-item--expanded' : 'nav-item'">
  <div class="nav-item-head" matRipple>
    <span class="nav-link" [title]="navigationItem.bezeichnung" (click)="emitItemClicked(navigationItem)">
      {{ navigationItem.bezeichnung }}
    </span>
    <span class="nav-dropdown-container" *ngIf="navigationItem.children" (click)="emitItemExpanded()">
      <mat-icon class="material-icons-outlined">keyboard_arrow_right</mat-icon>
    </span>
  </div>

  <ng-container *ngIf="navigationItem.children">
    <div class="nav-sub-container">
      <ul class="nav-sub-list">
        <li
          *ngFor="let item of navigationItem.children"
          class="nav-sub-item"
          [title]="item.bezeichnung"
          (click)="emitItemClicked(item)"
        >
          <span class="nav-link">{{ item.bezeichnung }}</span>
        </li>
      </ul>
    </div>
  </ng-container>
</div>

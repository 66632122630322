import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { SearchFieldComponent } from '../search-field/search-field.component';
import { SearchFieldModule } from '../search-field/search-field.module';

import { FieldOption, SearchBarSearchEvent } from './models';
import { QueryFieldSelectorComponent } from './query-field-selector/query-field-selector.component';

@Component({
  selector: 'mp-search-bar',
  standalone: true,
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatButtonModule, MatIconModule, SearchFieldModule, QueryFieldSelectorComponent],
})
export class SearchBarComponent {
  @Input() searchTerm = '';

  @Input() field!: string;

  @Input() fieldOptions: FieldOption[] = [];

  @Input() showFieldSelector = true;

  @Input() searchFieldPlaceholder = '';

  @Output() readonly search: EventEmitter<SearchBarSearchEvent> = new EventEmitter<SearchBarSearchEvent>();

  /**
   * The "manualSearchTriggered" output serves the purpose of handling pages that do not
   * require an automatic search triggered by a change in input values.
   * This output allows the parent component to make informed decisions
   * on whether to take action based on the search values.
   */
  @Output() readonly manualSearch: EventEmitter<SearchBarSearchEvent> = new EventEmitter<SearchBarSearchEvent>();

  @ViewChild('searchField') searchField!: SearchFieldComponent;

  private _lastManualSearchSearchField: string = this.field;

  private _lastManualSearchSearchTerm = '';

  onSearch(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.search.emit({ searchTerm, field: this.field });
  }

  onManualSearch(searchTerm: string): void {
    // As this can be spam-clicked we need to check if the search context has changed to prevent
    // unnecessary event emissions and hence redundant change detection cycles for parent components
    if (this.isNewManualSearchContext(searchTerm)) {
      this._lastManualSearchSearchField = this.field;
      this._lastManualSearchSearchTerm = searchTerm;
      this.manualSearch.emit({ searchTerm, field: this.field });
    }
  }

  onSearchQueryFieldSelected(field: string): void {
    this.field = field;
    this.search.emit({ searchTerm: this.searchTerm, field });
  }

  private isNewManualSearchContext(searchTerm: string): boolean {
    if (searchTerm !== this._lastManualSearchSearchTerm) {
      return true;
    }

    return this.hasFieldForManualSearchChanged();
  }

  private hasFieldForManualSearchChanged(): boolean {
    return this._lastManualSearchSearchField !== this.field;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { DateRange } from './date-range';

@Component({
  selector: 'mp-date-range-picker',
  standalone: true,
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatIconModule, MatButtonModule],
})
export class DateRangePickerComponent {
  @Input() fromLabel = 'Von';
  @Input() toLabel = 'Bis';

  @Input() minDate = new Date();

  @Input() startDate: Date | null = null;
  @Input() endDate: Date | null = null;

  @Output() readonly dateRangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  onDateChange(): void {
    this.dateRangeChange.emit(this.getDateRange());
  }

  private getDateRange(): DateRange {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
}

<a class="link" matRipple routerLinkActive="active" [routerLink]="[path]" [class.highlighted]="isHighlighted">
  <div class="content">
    <ng-content></ng-content>
  </div>
</a>
<div class="context">
  <button *ngIf="contextMenuButtonVisible" mat-icon-button color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-content select="[mat-menu-item]"></ng-content>
  </mat-menu>
</div>

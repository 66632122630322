import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe, DecimalPipe, NgTemplateOutlet, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInput } from '@angular/material/legacy-input';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TemplateContext, TypedTemplateDirective, UtilPipesModule } from '@core/shared/util';

import { FilterSearchFieldComponent } from '../../filter-search-field/filter-search-field.component';
import { FilterItem } from '../filter-item';

import { FilterItemManager } from './filter-item-manager';

type FilterItemStats = Record<number | string, number | undefined>;
type FilterItemIcons = Record<number | string, string | undefined>;

@Component({
  selector: 'mp-filter-item-selector',
  standalone: true,
  templateUrl: './filter-item-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    DecimalPipe,
    SlicePipe,
    NgTemplateOutlet,
    LetDirective,

    MatLegacyCheckboxModule,
    MatLegacyRadioModule,
    MatLegacyButtonModule,
    MatIconModule,

    UtilPipesModule,
    FilterSearchFieldComponent,
    TypedTemplateDirective,
  ],
})
export class FilterItemSelectorComponent<T = unknown> implements OnDestroy {
  @HostBinding('class') readonly class = 'mp-filter-item-selector';

  @Input() name?: string;
  @Input() searchFieldText = 'Merkmale durchsuchen';
  @Input() searchFieldAppearance: 'flat' | 'raised' = 'flat';

  @Input() genericOptionName: [string, string] = ['Merkmal', '-e'];

  @Input()
  get searchable() {
    return this._searchable;
  }

  set searchable(value: boolean) {
    this._searchable = coerceBooleanProperty(value);
    if (!this._searchable) {
      this.searchTerm$.next('');
    }
  }

  private _searchable = false;

  @Input()
  get showToggleAll() {
    return this._showToggleAll;
  }

  set showToggleAll(value: BooleanInput) {
    this._showToggleAll = coerceBooleanProperty(value);
  }

  private _showToggleAll = false;

  @Input()
  get showExpandAll() {
    return this._showExpandAll;
  }

  set showExpandAll(value: boolean) {
    this._showExpandAll = coerceBooleanProperty(value);
  }

  private _showExpandAll = false;

  @Input() sortedBySelection = false;

  /** Set to `true` per default. */
  @Input() set multiselect(isMultiselect: BooleanInput) {
    this.manager.setMultipleSelection(coerceBooleanProperty(isMultiselect));
  }

  @Input() set items(items: Array<FilterItem<T>>) {
    if (items !== undefined) {
      this.manager.setItems(items);
    }
  }

  @Input() set itemStats(itemStats: FilterItemStats) {
    if (itemStats !== undefined) {
      this._stats$.next(itemStats);
    }
  }

  @Input() set itemIcons(itemIcons: FilterItemIcons) {
    if (itemIcons !== undefined) {
      this._icons$.next(itemIcons);
    }
  }

  @Input() set maxVisibleItemCount(maxItemCount: number | 'all') {
    if (maxItemCount !== undefined) {
      this.maxVisibleItemCount$.next(maxItemCount);
    }
  }

  @Input() showStatusbar = false;
  @Input() hasBeenExpanded = false;

  @ViewChild('searchField') set searchField(searchField: FilterSearchFieldComponent) {
    const searchFieldInput: MatLegacyInput | undefined = searchField?.inputElement;

    if (searchFieldInput && !searchFieldInput.focused) {
      searchFieldInput.focus();
      this.cdr.detectChanges();
    }
  }

  @Output() readonly valueChanges = new EventEmitter<Array<T>>();
  @Output() readonly filterExpanded = new EventEmitter<boolean>();

  readonly allItems$: Observable<Array<FilterItem<T>>>;
  readonly allItemCount$: Observable<number>;
  readonly filteredItems$: Observable<Array<FilterItem<T>>>;
  readonly filteredItemCount$: Observable<number>;
  readonly selectedItemCount$: Observable<number>;

  readonly isAllSelected$: Observable<boolean>;
  readonly isPartiallySelected$: Observable<boolean>;
  readonly isCurrentlyFiltered$: Observable<boolean>;

  protected readonly filterItemsTemplateContextType!: TemplateContext<FilterItem<T>[]>;

  private readonly searchTerm$ = new BehaviorSubject(undefined as string | undefined);
  private readonly maxVisibleItemCount$ = new BehaviorSubject('all' as number | 'all');

  private readonly _showAllItems$ = new BehaviorSubject(false as boolean);
  readonly showAllItems$ = this._showAllItems$.asObservable();
  readonly showAllItemsDisabled$: Observable<boolean>;

  private readonly _stats$ = new BehaviorSubject({} as FilterItemStats);
  private readonly _icons$ = new BehaviorSubject({} as FilterItemIcons);

  readonly multiple$: Observable<boolean>;
  private manager: FilterItemManager<T>;

  showMore = true;
  selectedItemsCount = 0;
  filteredItemsCount = 0;
  allItemsCount = 0;
  searchTerm = '';

  get visibleItemCount(): number {
    if (!this.showExpandAll) {
      return this.allItemsCount;
    }

    if (this.hasBeenExpanded) {
      return this.searchTerm === undefined || this.searchTerm?.length <= 0
        ? this.allItemsCount
        : this.filteredItemsCount;
    }

    if (this.showMore && this.selectedItemsCount <= 3) return 3;

    return this.selectedItemsCount;
  }

  get shouldShowExpandMoreButton(): boolean {
    const comparableItemsCount =
      (this.searchTerm === undefined || this.searchTerm?.length <= 0) && this.allItemsCount > 10
        ? this.allItemsCount
        : this.filteredItemsCount;

    return this.showExpandAll && !this.hasBeenExpanded && this.visibleItemCount < comparableItemsCount && this.showMore;
  }

  get shouldShowCollapseExpandedFilter(): boolean {
    return (
      this.showExpandAll &&
      this.hasBeenExpanded &&
      (this.searchTerm?.length ? this.filteredItemsCount : this.allItemsCount) - this.visibleItemCount === 0 &&
      this.selectedItemsCount !== this.visibleItemCount
    );
  }

  private readonly filterLambda = (item: FilterItem<T>, searchTerm: string | undefined) => {
    if (!searchTerm) {
      return true;
    }

    const lowercaseSearchTerm = searchTerm.trim().toLowerCase();
    const lowercaseLabel = item.label.trim().toLowerCase();

    return lowercaseLabel.includes(lowercaseSearchTerm);
  };

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.manager = new FilterItemManager(true, []);

    this.multiple$ = this.manager.multiple$;
    this.showAllItemsDisabled$ = this.searchTerm$.pipe(map((term) => term !== undefined));

    this.allItems$ = this.buildAllItems$(this.manager.items$, this._stats$, this._icons$);
    this.filteredItems$ = this.buildFilteredItems$();

    this.allItemCount$ = this.allItems$.pipe(map((items) => items.length));
    this.filteredItemCount$ = this.filteredItems$.pipe(map((items) => items.length));
    this.isCurrentlyFiltered$ = combineLatest([this.allItemCount$, this.filteredItemCount$]).pipe(
      map(([allItemCount, filteredItemCount]) => allItemCount !== filteredItemCount),
    );

    this.isAllSelected$ = this.allItems$.pipe(map((items) => items.every((item) => item.selected)));

    this.isPartiallySelected$ = this.allItems$.pipe(
      map((items) => {
        const someSelected = items.some((item) => item.selected);
        const notAllSelected = !items.every((item) => item.selected);
        return someSelected && notAllSelected;
      }),
    );

    this.selectedItemCount$ = this.allItems$.pipe(
      map((items) => {
        const selectedItems = items.filter((item) => item.selected);
        return selectedItems.length;
      }),
    );

    this.manager.valueChanges$.pipe(takeUntilDestroyed()).subscribe({
      next: (selection) => {
        this.valueChanges.emit(selection);
      },
    });

    this.initFilteredItemsCountListener();
    this.initSelectedItemsCountListener();
    this.initAllItemsCountListener();
    this.initSearchTermListener();
  }

  private buildAllItems$(
    items$: Observable<Array<FilterItem<T>>>,
    stats$: Observable<FilterItemStats>,
    icons$: Observable<FilterItemIcons>,
  ): Observable<Array<FilterItem<T>>> {
    const enrichItems = (
      items: Array<FilterItem<T>>,
      stats: FilterItemStats,
      icons: FilterItemIcons,
      sortedBySelection: boolean,
    ): Array<FilterItem<T>> => {
      const allItems = items.map((item) => ({
        ...item,
        icon: item.icon || (icons ?? {})[item.key],
        stats: (stats ?? {})[item.key],
      }));

      return sortedBySelection ? this.sortedItemsBySelection(allItems) : allItems;
    };

    return combineLatest([items$, stats$, icons$]).pipe(
      map(([items, stats, icons]) => enrichItems(items, stats, icons, this.sortedBySelection)),
    );
  }

  private sortedItemsBySelection(items: FilterItem<T>[]): FilterItem<T>[] {
    return items.sort((item1, item2) => {
      if (item1.selected && !item2.selected) {
        return -1;
      }

      if (!item1.selected && item2.selected) {
        return 1;
      }

      return 0;
    });
  }

  private buildFilteredItems$(): Observable<Array<FilterItem<T>>> {
    return combineLatest([this.allItems$, this.searchTerm$, this.maxVisibleItemCount$, this._showAllItems$]).pipe(
      map(([allItems, searchTerm, maxItemCount, showAllItems]) => {
        if (searchTerm !== undefined) {
          return allItems.filter((item) => this.filterLambda(item, searchTerm));
        }

        if (showAllItems) {
          return allItems;
        }

        const itemCountToDisplay = maxItemCount === 'all' ? allItems.length : maxItemCount;
        return allItems.slice(0, itemCountToDisplay);
      }),
    );
  }

  ngOnDestroy(): void {
    this.manager.dispose();
  }

  emitNewSearchTerm(searchTerm: string): void {
    this.searchTerm$.next(searchTerm.trim().length > 0 ? searchTerm : undefined);
  }

  toggleShowAllItems(): void {
    const newState = !this._showAllItems$.getValue();
    this._showAllItems$.next(newState);
  }

  showAllItems(): void {
    this._showAllItems$.next(true);
    this.showMore = false;
    this.hasBeenExpanded = true;
    this.filterExpanded.emit(true);
  }

  collapseAllItem(): void {
    this._showAllItems$.next(false);
    this.showMore = true;
    this.hasBeenExpanded = false;
    this.filterExpanded.emit(false);
  }

  toggleSelectAll(): void {
    this.isAllSelected$.pipe(take(1), takeUntilDestroyed()).subscribe({
      next: (isAllSelected) => {
        this.manager[isAllSelected ? 'deselectAll' : 'selectAll']();
      },
    });
  }

  onFilterItemChanges({ selected, key }: { selected: boolean; key: string | number }): void {
    this.manager[selected ? 'select' : 'deselect'](key);
  }

  private initSelectedItemsCountListener(): void {
    this.selectedItemCount$.pipe(takeUntilDestroyed()).subscribe((selectedItemsCount) => {
      this.selectedItemsCount = selectedItemsCount;
    });
  }

  private initFilteredItemsCountListener(): void {
    this.filteredItemCount$.pipe(takeUntilDestroyed()).subscribe((filteredItemsCount) => {
      this.filteredItemsCount = filteredItemsCount;
    });
  }

  private initAllItemsCountListener(): void {
    this.allItemCount$.pipe(takeUntilDestroyed()).subscribe((allItemCount) => {
      this.allItemsCount = allItemCount;
    });
  }

  private initSearchTermListener(): void {
    this.searchTerm$.pipe(takeUntilDestroyed()).subscribe((searchTerm) => {
      this.searchTerm = searchTerm as unknown as string;
    });
  }
}

<ng-container *ngIf="{ isSmallViewport: isSmallViewport$ | async } as obj">
  <div
    class="list-container"
    [class.layout-full]="obj.isSmallViewport"
    [class.navigated]="obj.isSmallViewport && route.children.length > 0"
  >
    <div class="title-bar">
      <div class="title">
        {{ moduleTitle }}
        <span class="title__count" *ngIf="resultCount != null"> ({{ resultCount }}) </span>

        <ng-content select="[title-additional-content]"></ng-content>
      </div>

      <button mat-icon-button color="accent" aria-label="Toggle Search" (click)="toggleSearch()">
        <mat-icon>search</mat-icon>
      </button>

      <button
        *ngIf="contextMenuButtonVisible"
        mat-icon-button
        color="accent"
        aria-label="More Actions"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <ng-content select="[mat-menu-item]"></ng-content>
      </mat-menu>
    </div>

    <div class="search-bar" [hidden]="!isSearchVisible">
      <mat-form-field>
        <input
          matInput
          #searchField
          placeholder="{{ moduleTitle }} durchsuchen"
          (keyup)="emitSearchTerm(searchField.value)"
        />

        <button
          mat-button
          mat-icon-button
          *ngIf="searchField.value"
          matSuffix
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="list">
      <ng-content select="mp-infinite-scroll"></ng-content>
      <ng-content select="cdk-virtual-scroll-viewport"></ng-content>
      <ng-content select="mp-overview-item"></ng-content>
    </div>
  </div>

  <div
    class="detail-view-container"
    [class.layout-full]="obj.isSmallViewport"
    [class.navigated]="obj.isSmallViewport && route.children.length > 0"
  >
    <router-outlet></router-outlet>
  </div>
</ng-container>

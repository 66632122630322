import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { OrganisationsHierarchieData } from '@mp/system/organisationshierarchien/data-access';

@Injectable()
export class HierarchieEditorFacade {
  private readonly _hierarchieData$ = new BehaviorSubject(undefined as OrganisationsHierarchieData | undefined);
  readonly hierarchieData$ = this._hierarchieData$.asObservable();
  hierarchieData: OrganisationsHierarchieData | undefined = undefined;

  readonly usedOrganisationen$: Observable<Array<{ id: number }>>;

  private readonly nodesById = new Map<number, OrganisationsHierarchieData>();

  constructor() {
    this.usedOrganisationen$ = this.hierarchieData$.pipe(
      startWith({}),
      map(() => Array.from(this.nodesById.values()).map(({ organisationId: id }) => ({ id }))),
      shareReplay(1),
    );
  }

  initializeWithData(data?: OrganisationsHierarchieData): void {
    // Note: Since ngrx store states are immutable by default and object references are important here,
    // the state value needs to be cloned here to allow object assignments:
    // This crashes, when a self referencing (cyclic) object is passed in here.
    // So be sure to catch that beforehand!
    const dataDeepCopy = data ? JSON.parse(JSON.stringify(data)) : undefined;

    this.nodesById.clear();
    this.addNodeToNodesById(dataDeepCopy);

    this.emitNewValue(dataDeepCopy);
  }

  private addNodeToNodesById(node: OrganisationsHierarchieData): void {
    if (node) {
      this.nodesById.set(node.organisationId, node);

      node.children.forEach((child) => this.addNodeToNodesById(child));
    }
  }

  private emitNewValue(newValue: OrganisationsHierarchieData | undefined): void {
    const currentValue = this.hierarchieData;
    if (currentValue || newValue) {
      this.hierarchieData = newValue;
      this._hierarchieData$.next(newValue);
    }
  }

  addNode(newNode: OrganisationsHierarchieData): void {
    this.nodesById.set(newNode.organisationId, newNode);

    if (newNode.parentOrganisationId !== null) {
      const parentNode = this.nodesById.get(newNode.parentOrganisationId);
      parentNode?.children.push(newNode);

      const currentTopNode = this.hierarchieData;
      this.emitNewValue(currentTopNode);
    } else {
      this.emitNewValue(newNode);
    }
  }

  removeNode(organisationId: number): void {
    const nodeToRemove = this.nodesById.get(organisationId);
    if (!nodeToRemove) {
      throw new Error(`No node with organisationId "${organisationId}"!`);
    }

    const nodeToRemoveIsTopLevel = this.nodesById.size === 1;

    if (nodeToRemove.parentOrganisationId !== null && !nodeToRemove.children.length) {
      const parentNode = this.nodesById.get(nodeToRemove.parentOrganisationId) as OrganisationsHierarchieData;
      parentNode.children = parentNode.children.filter((child) => child.organisationId !== organisationId);

      this.nodesById.delete(organisationId);

      this.emitNewValue(this.hierarchieData);
    } else if (nodeToRemoveIsTopLevel) {
      this.nodesById.delete(organisationId);

      this.emitNewValue(undefined);
    } else {
      throw new Error('Node to be removed has children!');
    }
  }

  reset(): void {
    this.nodesById.clear();
    this.emitNewValue(undefined);
  }
}

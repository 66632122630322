import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { CategoryNavigationItem, CategoryNavigationItemWithParent } from '@mp/medical-shop/artikelsuche/data-access';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRippleModule } from '@angular/material/core';
import { WarengruppenNavigationItemComponent } from './warengruppen-navigation-item/warengruppen-navigation-item.component';
import { CommonModule } from '@angular/common';

// TODO: This can be removed when the old Medical Shop is removed
@Component({
  selector: 'mp-warengruppen-navigation',
  standalone: true,
  templateUrl: './warengruppen-navigation.component.html',
  styleUrl: './warengruppen-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatListModule,
    MatRippleModule,
    WarengruppenNavigationItemComponent
  ],
})
export class WarengruppenNavigationComponent implements OnInit {
  @HostBinding('class') readonly class = 'mp-warengruppen-navigation';

  @HostBinding('class.is--collapsed') get collapsed(): boolean {
    return this.isCollapsed;
  }

  @Input() breadcrumb: Array<CategoryNavigationItemWithParent> = [];
  @Input() warengruppe: Array<CategoryNavigationItem> = [];
  @Input() currentWarengruppe?: CategoryNavigationItem;

  @Output() readonly navigationChanges = new EventEmitter<CategoryNavigationItem | null>();

  activeDropdownCode: string | null = null;

  private isCollapsed = false;
  private mobileViewport = false;

  get showDefaultLabel(): boolean {
    return !this.currentWarengruppe || (this.currentWarengruppe && !this.isCollapsed);
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    if (this.mobileViewport && !this.isCollapsed && !this._eref.nativeElement.contains(event.target)) {
      this.collapsePanel();
    }
  }

  constructor(
    private readonly _eref: ElementRef,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 1024px)'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ matches }: BreakpointState) => {
          this.mobileViewport = !matches;
          if (!matches) {
            this.collapsePanel();
          }
        },
      });
  }

  handleItemExpanded(item: CategoryNavigationItem): void {
    this.activeDropdownCode = this.activeDropdownCode !== item.code ? item.code : null;
  }

  emitItemClicked(item: CategoryNavigationItem | null): void {
    this.navigationChanges.emit(item);
  }

  handleRootClicked(event: Event): void {
    this.activeDropdownCode = null;
    this.currentWarengruppe = undefined;

    this.expandPanel(event);
    this.emitItemClicked(null);
  }

  expandPanel(event: Event): void {
    event.stopPropagation();
    this.isCollapsed = false;
  }

  collapsePanel(): void {
    this.isCollapsed = true;
  }
}

import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { Pagination } from '@mp/shared/data-access';

export type PagedDataFetcher<T, TParams extends Record<string, unknown> | undefined = undefined> = (
  pagination: Pagination,
  params: TParams,
) => Observable<{ hasMore: boolean; results: Array<T> }>;

export class PagedDataSource<T, TParams extends Record<string, unknown> | undefined = undefined> extends DataSource<T> {
  fetchedPages = new Map<number, Array<T>>();

  private readonly _data$ = new BehaviorSubject([] as Array<T>);
  readonly data$ = this._data$.asObservable();

  private readonly _queryParams$: BehaviorSubject<TParams>;
  readonly queryParams$: Observable<TParams>;

  private readonly _isLoading$ = new BehaviorSubject(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  private readonly _hasMore$ = new BehaviorSubject(true as boolean);
  readonly hasMore$ = this._hasMore$.asObservable();

  get length(): number {
    return this._data$.getValue().length;
  }

  get data(): Array<T> {
    return this._data$.getValue();
  }

  get queryParams(): TParams {
    return this._queryParams$.getValue();
  }

  viewChangeSubscription?: Subscription;

  constructor(
    private readonly dataFetcher: PagedDataFetcher<T, TParams>,
    initialParams = undefined as TParams,
    private readonly pageSize = 50,
  ) {
    super();

    this._queryParams$ = new BehaviorSubject(initialParams as TParams);
    this.queryParams$ = this._queryParams$.asObservable();
  }

  reset(): void {
    this.fetchedPages.clear();
    this.emitNewData();
  }

  connect(collectionViewer: CollectionViewer): Observable<Array<T>> {
    /*
     * TODO: Hier muss folgender Edge-Case behandelt werden:
     * Ist die gesetzte Page-Size zu klein für die dargestellte Höhe des Viewports,
     * kann es sein, dass (initial) mehrere Seiten auf einmal geladen werden müssen,
     * um den Viewport vollständig befüllen zu können.
     *
     * Dafür kann folgender Code verwendet werden:
     *
     * ```ts
     * this.viewChangeSubscription =
     *   collectionViewer
     *     .viewChange
     *     .pipe(
     *       map(listRange => this.listRangeToPages(listRange)),
     *       mergeMap(pages => forkJoin(
     *         pages.map(page => this.fetchMore(page, this.pageSize))
     *       ))
     *     )
     *     .subscribe({ next: () => { ... } })
     * ```
     */

    this.viewChangeSubscription = this.queryParams$
      .pipe(
        mergeMap((params) => {
          const page = this.fetchedPages.size + 1;
          return this.fetchUsing({ page, pageSize: this.pageSize }, params);
        }),
      )
      .subscribe({
        next: () => {
          this.emitNewData();
        },
      });

    return this.data$;
  }

  disconnect(): void {
    this.viewChangeSubscription?.unsubscribe();
    this.viewChangeSubscription = undefined;
    this.reset();
  }

  private fetchUsing(pagination: { page: number; pageSize: number }, params: TParams): Observable<unknown> {
    this._isLoading$.next(true);

    return this.dataFetcher(pagination, params).pipe(
      tap(({ results, hasMore }) => {
        this._isLoading$.next(false);
        this._hasMore$.next(hasMore);
        this.fetchedPages.set(pagination.page, results);
      }),
    );
  }

  private fetchPage(page: number): Observable<unknown> {
    const pagination = {
      page,
      pageSize: this.pageSize,
    };
    const params = this._queryParams$.getValue();
    return this.fetchUsing(pagination, params).pipe(tap(() => this.emitNewData()));
  }

  fetchMore(): Observable<unknown> {
    return this.fetchPage(this.fetchedPages.size + 1);
  }

  refreshPageContaining(index: number): Observable<unknown> {
    return this.fetchPage(this.listIndexToPage(index));
  }

  updateQueryParams(partialParams: Partial<TParams>): void {
    const currentParams = this._queryParams$.getValue();
    this.fetchedPages.clear();
    this._queryParams$.next({ ...currentParams, ...partialParams });
  }

  /* Currently not needed, but might be useful, when fixing the pageSize < viewport-height edge-case! */
  private listRangeToPages({ start, end }: ListRange): Array<number> {
    const startPage = this.listIndexToPage(start);
    const endPage = this.listIndexToPage(end - 1);

    if (startPage === endPage) return [startPage];
    return Array.from<number>({ length: endPage - startPage + 1 }).map((_, index) => index + startPage);
  }

  private listIndexToPage(index: number): number {
    return Math.floor(index / this.pageSize) + 1;
  }

  private emitNewData(): void {
    const data = Array.from(this.fetchedPages.entries())
      .sort(([keyA], [keyB]) => keyA - keyB)
      .map(([, value]) => value)
      .reduce((prev, curr) => [...prev, ...curr], []);

    this._data$.next(data);
  }
}

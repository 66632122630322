import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { LocalStorageService } from '@core/shared/data-access';

import { CollapsedFiltersComponent } from './collapsed-filters/collapsed-filters.component';
import { FilterInfo } from './filter-info';

const ASIDE_FILTERS_EXPANSION_STATE_KEY = 'asideFiltersExpanded';

@Component({
  selector: 'mp-aside-filters',
  standalone: true,
  templateUrl: './aside-filters.component.html',
  styleUrls: ['./aside-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, MatIconModule, MatButtonModule, CollapsedFiltersComponent],
})
export class AsideFiltersComponent {
  @Input() filtersSummary: FilterInfo[] = [];

  @Output() readonly filtersReset: EventEmitter<void> = new EventEmitter<void>();

  isExpanded: boolean | string = this.localStorageService.read(ASIDE_FILTERS_EXPANSION_STATE_KEY, true);

  constructor(private readonly localStorageService: LocalStorageService) {}

  toggleExpandedState(): void {
    this.isExpanded = !this.isExpanded;
    this.localStorageService.write(ASIDE_FILTERS_EXPANSION_STATE_KEY, this.isExpanded);
  }

  resetFilters(): void {
    this.filtersReset.emit();
  }
}

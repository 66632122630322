<mat-form-field
  class="autocomplete-input-form-field"
  [ngClass]="{
    'autocomplete-input-form-field--standard-appearance': appearance() === 'standard',
    'autocomplete-input-form-field--inline': inline()
  }"
  appearance="fill"
>
  @if (autocompleteIcon(); as autocompleteIcon) {
    <mat-icon
      class="autocomplete-input-form-field__icon material-icons-outlined"
      [mpIcon]="autocompleteIcon"
      matPrefix
    />
  }

  @if (!inline()) {
    <mat-label>
      @if (labelTemplate(); as labelTemplate) {
        <ng-container *ngTemplateOutlet="labelTemplate" />
      } @else {
        {{ label() }}
      }
    </mat-label>
  }

  @switch (autocompleteInputMode()) {
    @case ('chip-item') {
      <mat-chip-list #chipList [disabled]="disabled()" [required]="required()">
        @if (selectedValue(); as selectedValue) {
          <mat-chip class="autocomplete-input-form-field__chip-item truncate">
            <ng-container
              *ngTemplateOutlet="
                chipItemTemplate() ?? defaultChipItemTemplate.template;
                context: { $implicit: selectedValue }
              "
            />
          </mat-chip>
        }

        <input
          #input
          class="autocomplete-input-form-field__chip-input truncate"
          [ngClass]="{
            'autocomplete-input-form-field__chip-input--hidden': !!selectedValue(),
            'autocomplete-input-form-field__chip-input--filled': !!input.value
          }"
          [(ngModel)]="selectedValue"
          [placeholder]="placeholder() ?? ''"
          [matChipInputFor]="chipList"
          [matAutocomplete]="autocompletePanel().matAutocomplete"
          (blur)="onTouched()"
          (input)="onSearchTermChange(input.value)"
          (ngModelChange)="onSelectedValueChange($event); resetChipInput(input)"
        />
      </mat-chip-list>
    }
    @default {
      <input
        #input
        class="autocomplete-input-form-field__input"
        [ngClass]="{ 'autocomplete-input-form-field__input--filled': !!input.value }"
        type="text"
        matInput
        [(ngModel)]="selectedValue"
        [placeholder]="placeholder() ?? ''"
        [disabled]="disabled()"
        [required]="required()"
        [matAutocomplete]="autocompletePanel().matAutocomplete"
        (blur)="onTouched()"
        (input)="onSearchTermChange(input.value)"
        (ngModelChange)="onSelectedValueChange($event)"
      />
    }
  }

  @if (selectedValue() && clearable()) {
    <button
      class="autocomplete-input-form-field__clear-button"
      mat-icon-button
      matSuffix
      [disabled]="!!selectedValue() && disabled()"
      (click)="onClear()"
    >
      <mat-icon class="autocomplete-input-form-field__clear-icon">clear</mat-icon>
    </button>
  } @else {
    <mat-icon class="autocomplete-input-form-field__dropdown-icon" matSuffix>arrow_drop_down</mat-icon>
  }

  @if (errorMessage()) {
    <mat-error>{{ errorMessage() }}</mat-error>
  }
</mat-form-field>

<ng-template
  #defaultChipItemTemplate="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="chipItemTemplateContextType"
  let-selectedValue
>
  <span class="font-bold">{{ selectedValue }}</span>
</ng-template>

<a
  class="nav-element"
  [ngClass]="{ 'nav-element--disabled': disabled }"
  matRipple
  [routerLink]="disabled ? null : navLink"
  [routerLinkActiveOptions]="{ exact }"
  (isActiveChange)="emitIsActiveChange($event)"
  routerLinkActive="nav-element--active"
>
  <ng-content></ng-content>
</a>

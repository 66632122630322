<div class="avatar-wrapper">
  @if (image) {
    <img
      class="avatar"
      (error)="handleImageLoadError($event)"
      [class.tiny]="size === 'tiny'"
      [class.small]="size === 'small'"
      [class.medium]="size === 'medium'"
      [class.large]="size === 'large'"
      [src]="image"
    />
  } @else {
    @if (_initials) {
      <div
        class="avatar"
        [class.tiny]="size === 'tiny'"
        [class.small]="size === 'small'"
        [class.medium]="size === 'medium'"
        [class.large]="size === 'large'"
      >
        {{ _initials }}
      </div>
    } @else {
      <img
        src="assets/Avatar_Placeholder.jpeg"
        class="avatar"
        [class.tiny]="size === 'tiny'"
        [class.small]="size === 'small'"
        [class.medium]="size === 'medium'"
        [class.large]="size === 'large'"
      />
    }
  }

  @if (_isUpload) {
    @if ((isLoading$ | async) === false) {
      <button mat-button color="accent" [matMenuTriggerFor]="menu">
        <mat-icon>add_photo_alternate</mat-icon>
        BILD ÄNDERN
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="fileInput.click()">
          Bild auswählen
        </button>

        @if (image) {
          <button mat-menu-item (click)="clearImage()">
            Bild entfernen
          </button>
        }
      </mat-menu>
    } @else {
      <mp-spinner />
    }

    <input #fileInput hidden type="file" [accept]="acceptedFormats" (change)="onFileChange($event)" />
  }
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Identifiable } from '@core/shared/data-access';
import { FlyoutManager } from '@core/shared/util';

import { SelectionItem } from '../flyout/selector/selection-item/selection-item';
import { SelectionItemBuilder } from '../flyout/selector/selection-item/selection-item-builder';
import { SelectorFlyoutService } from '../flyout/selector/selector-flyout.service';

import { Organisation } from './organisation';

@Injectable()
export class OrganisationsHierarchienFlyoutFacade extends FlyoutManager {
  constructor(private readonly flyoutSelectorService: SelectorFlyoutService) {
    super();
  }

  promptOrganisationenFlyout(selectionItems$: Observable<Array<SelectionItem<Organisation>>>, hasParent: boolean) {
    if (this.flyoutCurrentlyOpened) {
      return undefined;
    }

    const flyoutRef = hasParent
      ? this.flyoutSelectorService.openMultipleSelection('Tochter-Organisationen auswählen', selectionItems$)
      : this.flyoutSelectorService.openSingleSelection('Dachorganisation auswählen', selectionItems$);

    this.markFlyoutAsOpen();
    this.subscribeToAfterClosed(flyoutRef, () => this.markFlyoutAsClosed());

    return flyoutRef;
  }

  buildOrganisationSelectionItems$(
    selectableOrganisationen: Array<Organisation>,
    organisationenToExclude$: Observable<Array<Identifiable>>,
  ): Observable<Array<SelectionItem<Organisation>>> {
    return organisationenToExclude$.pipe(
      take(1),
      map((toExclude) =>
        SelectionItemBuilder.fromArray(selectableOrganisationen)
          .header('name')
          .exclude((organisation) => toExclude.some(({ id }) => id === organisation.id))
          .build(),
      ),
    );
  }
}

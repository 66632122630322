import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AvatarComponent } from '../avatar/avatar.component';
import { ElementDirective } from '../core/element';

@Component({
  selector: 'mp-nav-profile',
  standalone: true,
  templateUrl: './navigation-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent],
})
export class NavigationProfileComponent extends ElementDirective {
  @Input() user?: string | null;
  @Input() company?: string | null;
  @Input() picture?: string | null;

  constructor() {
    super();
    this.class = 'mp-nav-profile';
  }
}

<div class="entity-list-head__label truncate">
  <span class="entity-list-head__count">{{
    count | autoSingularOrPlural: genericEntityName[0] : genericEntityName[1]
  }}</span>
</div>
<div class="entity-list-head__actions">
  @if (showSearch) {
    <mp-search-field #searchField [displayValue]="searchTerm" (valueChanges)="onSearchTermEmitted($event)">
      <button mat-icon-button mpSearchFieldButton="left" (click)="searchField.search(searchField.value)">
        <mat-icon>search</mat-icon>
      </button>
      @if (searchField.value?.length) {
        <button mat-icon-button mpSearchFieldButton="right" (click)="onSearchCleared()">
          <mat-icon>clear</mat-icon>
        </button>
      }
    </mp-search-field>
  }

  <ng-content />
</div>

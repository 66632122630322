import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { ListResultsInfoComponent } from './list-results-info/list-results-info.component';

@Component({
  selector: 'mp-list-header',
  standalone: true,
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, LetDirective, ListResultsInfoComponent],
})
export class ListHeaderComponent {
  @HostBinding() readonly class = 'mp-list-header';

  @Input() headerValue: string | undefined;

  @Input() headerPlaceholder = '';

  @Input() showListHeaderSearchLabel = false;

  @Input() listHeaderSearchLabel = 'Suche nach';

  @Input() selectedItemsQuantity = 0;

  @Input() totalItemsQuantity = 0;
}

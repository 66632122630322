<ng-template #empty>
  <div *ngIf="!readonly" class="empty-template">
    <button mat-icon-button (click)="addNodes.emit(null)">
      <mat-icon class="add-button-icon">add_circle</mat-icon>
    </button>
  </div>
</ng-template>

<ng-container *ngIf="data; else empty">
  <div class="self-container">
    <div class="connector" *ngIf="data.parentOrganisationId !== null"></div>
    <span>{{ data.organisationName }}</span>

    <button
      mat-icon-button
      *ngIf="data.children.length === 0 && !readonly"
      class="remove-node"
      (click)="removeNode.emit(data.organisationId)"
    >
      <mat-icon>close</mat-icon>
    </button>

    <button mat-icon-button *ngIf="!readonly" class="add-node" (click)="addNodes.emit(data.organisationId)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="children-container">
    <mp-organisationshierarchie-node
      *ngFor="let node of data.children"
      [readonly]="readonly"
      [data]="node"
      (removeNode)="removeNode.emit($event)"
      (addNodes)="addNodes.emit($event)"
    >
    </mp-organisationshierarchie-node>
  </div>
</ng-container>

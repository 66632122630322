import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LetDirective } from '@ngrx/component';

import { OrganisationshierarchieEditorComponent } from './editor/organisationshierarchie-editor.component';
import { HierarchieEditorFacade } from './hierarchie-editor.facade';
import { OrganisationsHierarchieNodeComponent } from './node/organisationshierarchie-node.component';
import { OrganisationsHierarchienFlyoutFacade } from './organisationshierarchien-flyout.facade';

@NgModule({
  exports: [OrganisationshierarchieEditorComponent],
  declarations: [OrganisationshierarchieEditorComponent, OrganisationsHierarchieNodeComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, LetDirective],
  providers: [HierarchieEditorFacade, OrganisationsHierarchienFlyoutFacade],
})
export class OrganisationshierarchieEditorModule {}

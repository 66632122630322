<div class="list-header">
  <div class="list-header__prefix">
    <ng-content select="[list-header-prefix]"></ng-content>
  </div>

  <div class="list-header__content list-header-content truncate">
    <span
      class="list-header-content__label"
      *ngIf="showListHeaderSearchLabel && headerValue"
      [title]="listHeaderSearchLabel"
    >
      {{ listHeaderSearchLabel }}
    </span>

    <h2
      class="list-header-content__title truncate"
      *ngrxLet="headerValue || headerPlaceholder as headerText"
      [title]="headerText"
    >
      <strong>{{ headerText }}</strong>
    </h2>

    <ng-content select="[list-header-subtitle]"></ng-content>

    <mp-list-results-info
      class="truncate"
      [totalItemsQuantity]="totalItemsQuantity"
      [selectedItemsQuantity]="selectedItemsQuantity"
    />
  </div>
</div>

<div class="list-header-context">
  <div class="list-header-context__actions">
    <ng-content></ng-content>
  </div>
  <div class="list-header-context__additional">
    <ng-content select="[list-header-additional-context]"></ng-content>
  </div>
</div>

import { Clipboard } from '@angular/cdk/clipboard';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject, of } from 'rxjs';
import { delay, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mp-copy-to-clipboard-button',
  standalone: true,
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, LetDirective, MatButtonModule, MatIconModule, MatLegacyTooltipModule],
})
export class CopyToClipboardButtonComponent {
  @HostBinding('class') readonly class = 'mp-copy-to-clipboard-button';

  @Input() text = '';

  private readonly _textCopied$: Subject<void> = new Subject<void>();

  textCopied$: Observable<boolean> = this._textCopied$.asObservable().pipe(
    switchMap(() => of(false).pipe(delay(2000), startWith(true))),
    startWith(false),
  );

  constructor(private readonly clipboard: Clipboard) {}

  copyTextToClipboard(): void {
    if (this.isDisabled()) {
      return;
    }

    const isCopiedToClipboard = this.clipboard.copy(this.text);
    if (isCopiedToClipboard) {
      this._textCopied$.next();
    }
  }

  isDisabled(): boolean {
    return !this.text;
  }
}

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-selector-item',
  standalone: true,
  templateUrl: './selector-item.component.html',
  styleUrls: ['./selector-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatRippleModule, MatIconModule],
})
export class SelectorItemComponent implements OnDestroy {
  @HostBinding('class') readonly class = 'mp-selector-item';

  @Output() readonly toggled = new EventEmitter<boolean>();

  @Input()
  get selected(): BooleanInput {
    return this._selected;
  }
  set selected(value: BooleanInput) {
    this._selected = coerceBooleanProperty(value);
  }
  private _selected = false;

  @Input()
  get disabled(): BooleanInput {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostListener('click') click(): void {
    if (!this.disabled) {
      this.selected = !this.selected;
      this.toggled.next(this.selected);
    }
  }

  ngOnDestroy(): void {
    this.toggled.complete();
  }
}
